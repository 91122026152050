<template>
  <v-container grid-list-md pa-0 relative class="page-layout">
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card>
            <v-card-title style="" class="title">
              &nbsp; ADD ITEM/PRODUCT INFORMATION
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card outlined>
              <div class="item_form_container" style="margin-top: 10px">
                <div class="item_form">
                  <v-form
                    ref="form"
                    @submit.prevent="save"
                    @keydown.native="form.errors.clear($event.target.name)"
                    @keyup.enter="save"
                    lazy-validation
                  >
                    <v-container grid-list-md class="pa-0">
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-text-field
                            v-model="form.name"
                            name="name"
                            outlined
                            dense
                            label="Item Name"
                            class="pa-1"
                            :error-messages="form.errors.get('name')"
                            :height="25"
                            autocomplete="off"
                          />
                        </v-flex>

                        <v-flex xs6>
                          <v-text-field
                            outlined
                            dense
                            v-model="form.print_name"
                            name="print_name"
                            label="Print Name"
                            class="pa-1"
                            :error-messages="form.errors.get('print_name')"
                            :height="25"
                            autocomplete="off"
                          />
                        </v-flex>
                        <v-flex xs5>
                          <v-select
                            outlined
                            dense
                            :items="category"
                            label="Category"
                            :height="25"
                            class="pa-1"
                            v-model="form.category_id"
                            @change="getItemCode()"
                          ></v-select>
                        </v-flex>
                        <v-flex xs1>
                          <add-category-button
                            @onSuccess="getCategory"
                          ></add-category-button>
                        </v-flex>
                        <v-flex xs5>
                          <v-select
                            outlined
                            dense
                            :items="unit"
                            label="Unit"
                            :height="19"
                            class="pa-1"
                            v-model="form.unit_id"
                          ></v-select>
                        </v-flex>
                        <v-flex xs1>
                          <div>
                            <add-unit-button
                              @onSuccess="getUnit"
                            ></add-unit-button>
                          </div>
                        </v-flex>
                        <v-flex xs6>
                          <v-text-field
                            outlined
                            dense
                            v-model="form.item_code"
                            name="item_code"
                            label="Item Code"
                            class="pa-1"
                            :error-messages="form.errors.get('item_code')"
                            :height="25"
                            autocomplete="off"
                            disabled
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            outlined
                            dense
                            v-model="form.purchase_rate"
                            name="purchase_rate"
                            label="Purchase Rate"
                            class="pa-1"
                            :error-messages="form.errors.get('purchase_rate')"
                            :height="25"
                            type="number"
                            autocomplete="off"
                          />
                        </v-flex>

                        <v-flex xs3>
                          <v-text-field
                            outlined
                            dense
                            v-model="form.sales_rate"
                            name="sales_rate"
                            label="Sales Rate"
                            class="pa-1"
                            type="number"
                            :error-messages="form.errors.get('sales_rate')"
                            :height="25"
                            autocomplete="off"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            outlined
                            dense
                            v-model="form.min_sales_rate"
                            name="min_sales_rate"
                            label="Min Sales Rate"
                            class="pa-1"
                            type="number"
                            :error-messages="form.errors.get('min_sales_rate')"
                            :height="25"
                            autocomplete="off"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            outlined
                            dense
                            v-model="form.mrp_sales"
                            name="mrp_sales"
                            label="MRP Sales"
                            class="pa-1"
                            type="number"
                            :error-messages="form.errors.get('mrp_sales')"
                            :height="25"
                            autocomplete="off"
                          />
                        </v-flex>

                        <v-flex xs6>
                          <v-checkbox
                            style="margin-top: 0px !important"
                            class="pa-1"
                            color="blue"
                            v-model="form.is_service_type"
                            label="Service Type"
                          >
                          </v-checkbox>
                          <p
                            style="
                              line-height: 10px;
                              margin: -20px 0px 0px 37px;
                            "
                          >
                            <small
                              style="
                                color: #777;
                                font-size: 12px;
                                font-weight: normal;
                                line-height: 15px;
                              "
                            >
                              <!-- Class teacher, administrators will be able to take
                              admission for previous dates of service types. -->
                              Choose, if Item is service type
                            </small>
                          </p>
                        </v-flex>

                        <v-flex xs6>
                          <v-checkbox
                            style="margin-top: 0px !important"
                            class="pa-1"
                            color="blue"
                            v-model="form.is_taxable"
                            label="Taxable"
                          >
                          </v-checkbox>
                          <p
                            style="
                              line-height: 10px;
                              margin: -20px 0px 0px 37px;
                            "
                          >
                            <small
                              style="
                                color: #777;
                                font-size: 12px;
                                font-weight: normal;
                                line-height: 15px;
                              "
                            >
                              <!-- Class teacher, administrators will be able to take
                              admission for previous dates of taxable price. -->
                              Choose, if Item contains VAT
                            </small>
                          </p>
                        </v-flex>

                        <v-flex xs12>
                          <p style="margin-top: 20px; margin-bottom: 0px">
                            Notification On
                          </p>
                          <p
                            style="
                              font-size: 12px;
                              color: #777;
                              margin-bottom: 0px;
                            "
                          >
                            Notification will be triggered on web / mobile / sms
                            whenever the below specified quantity / level is
                            reached
                          </p>
                        </v-flex>
                        <v-flex xs4>
                          <v-text-field
                            outlined
                            dense
                            v-model="form.notify.min_qty"
                            name="min_qty"
                            label="Min. Quantity"
                            class="pa-1"
                            type="number"
                            :error-messages="form.errors.get('min_qty')"
                            :height="25"
                            autocomplete="off"
                          />
                        </v-flex>
                        <v-flex xs4>
                          <v-text-field
                            outlined
                            dense
                            v-model="form.notify.max_qty"
                            name="max_qty"
                            label="Max Quantity"
                            class="pa-1"
                            type="number"
                            :error-messages="form.errors.get('max_qty')"
                            :height="25"
                            autocomplete="off"
                          />
                        </v-flex>
                        <v-flex xs4>
                          <v-text-field
                            outlined
                            dense
                            v-model="form.notify.reorder_level"
                            name="reorder_level"
                            label="Reorder Level"
                            class="pa-1"
                            type="number"
                            :error-messages="form.errors.get('reorder_level')"
                            :height="25"
                            autocomplete="off"
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </div>
                <div class="item_info">
                  <v-form
                    ref="form"
                    @submit.prevent="save"
                    @keydown.native="form.errors.clear($event.target.name)"
                    @keyup.enter="save"
                    lazy-validation
                  >
                    <v-container grid-list-md class="pa-0">
                      <v-layout row wrap>
                        <v-flex xs11>
                          <v-select
                            outlined
                            dense
                            :items="warehouse"
                            label="Ware House"
                            :height="19"
                            v-model="addItemData.warehouse_id"
                          ></v-select>
                        </v-flex>
                        <v-flex xs1>
                          <div>
                            <add-ware-house-button
                              @onSuccess="getWarehouse"
                            ></add-ware-house-button>
                          </div>
                        </v-flex>
                        <v-flex xs4>
                          <v-text-field
                            outlined
                            dense
                            v-model="addItemData.qty"
                            name="qty"
                            label="Opening Quantity"
                            :error-messages="form.errors.get('qty')"
                            type="number"
                            autocomplete="off"
                          />
                        </v-flex>
                        <v-flex xs4>
                          <v-text-field
                            outlined
                            dense
                            v-model="addItemData.rate"
                            name="rate"
                            label="Opening Rate"
                            :error-messages="form.errors.get('rate')"
                            type="number"
                            autocomplete="off"
                          />
                        </v-flex>
                        <v-flex xs4>
                          <v-text-field
                            outlined
                            dense
                            v-model="addItemData.amount"
                            name="amount"
                            label="Amount"
                            :error-messages="form.errors.get('amount')"
                            type="number"
                            autocomplete="off"
                          />
                        </v-flex>
                        <v-btn
                          v-if="update"
                          style="margin-top: 10px"
                          color="success"
                          outlined
                          text
                          @click="updateItem(addItemData)"
                          :disabled="
                            !(
                              addItemData.warehouse_id &&
                              addItemData.amount >= 0 &&
                              addItemData.rate >= 0 &&
                              addItemData.qty
                            )
                          "
                        >
                          Update
                        </v-btn>
                        <v-btn
                          v-else
                          style="margin-top: 10px"
                          color="success"
                          outlined
                          text
                          @click="addItem(addItemData)"
                          :disabled="
                            !(
                              addItemData.warehouse_id &&
                              addItemData.amount >= 0 &&
                              addItemData.rate >= 0 &&
                              addItemData.qty
                            )
                          "
                        >
                          OK
                        </v-btn>

                        <v-flex xs12>
                          <v-data-table
                            style="margin-top: 20px"
                            :headers="headers"
                            :items="form.item_data"
                            item-key="key"
                            hide-default-footer
                          >
                            <!-- <template slot="items" slot-scope="props"> -->
                            <template v-slot:item="{ index, item }">
                              <tr>
                                <td>{{ ++index }}</td>
                                <td class="text-xs-left">
                                  {{
                                    findWareHouse(item.warehouse_id)
                                      ? findWareHouse(item.warehouse_id).text
                                      : ''
                                  }}
                                </td>
                                <td class="text-xs-left">{{ item.qty }}</td>
                                <td class="text-xs-left">
                                  {{ item.rate }}
                                </td>
                                <td class="text-xs-left">
                                  {{ item.amount }}
                                </td>
                                <td class="text-right">
                                  <v-icon
                                    small
                                    @click="editItem(item, index)"
                                    style="
                                      margin-right: 2px;
                                      background: blue;
                                      color: white;
                                    "
                                  >
                                    edit
                                  </v-icon>

                                  <v-icon
                                    small
                                    @click="removeItem(item)"
                                    style="background: red; color: white"
                                    >clear
                                  </v-icon>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </div>
              </div>
            </v-card>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="warning"
                outlined
                text
                @click="(form.dialog = false), form.reset()"
                >Cancel
              </v-btn>
              <v-btn color="success" outlined text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';
import AddUnitButton from '../../../../components/AddUnitButton';

const NepaliDate = require('nepali-date');
const nd = new NepaliDate();

export default {
  components: { AddUnitButton },
  data: () => ({
    form: new Form(
      {
        name: '',
        print_name: '',
        item_code: '',
        category_id: '',
        unit_id: '',
        is_service_type: 0,
        purchase_rate: '',
        sales_rate: '',
        mrp_sales: '',
        min_sales_rate: '',
        is_taxable: 0,
        item_data: [],
        notify: {
          min_qty: '',
          max_qty: '',
          reorder_level: '',
        },
      },
      '/api/account/inventory/item'
    ),
    searchAH: null,
    searchC: null,
    searchW: null,
    searchU: null,
    isLoading: false,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
    },
    categorySearchData: [],
    wareHouseSearchData: [],
    unitsSearchData: [],
    accountHeads: [],
    addItemData: {
      key: '',
      id: '',
      warehouse_id: '',
      warehouse_name: '',
      qty: '',
      rate: '',
      // unit_id: '',
      // unit_name: '',
      amount: '',
    },
    editIndex: '',
    dialog: false,
    update: false,
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'id', width: '20px' },
      { text: 'Ware House', value: 'warehouse', align: 'left', width: '30%' },
      {
        text: 'Qty',
        value: 'qry',
        align: 'left',
        width: '20%',
        sortable: false,
      },
      {
        text: 'Rate',
        value: 'rate',
        align: 'left',
        width: '20%',
        sortable: false,
      },
      // {text: 'Unit', value: 'unit', sortable: false, align: 'right'},
      { text: 'Amount', value: 'amount', sortable: false, align: 'right' },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        align: 'right',
        width: 120,
      },
    ],
    category: [],
    unit: [],
    warehouse: [],
  }),

  computed: {
    ...mapState(['batch']),
    items() {
      return this.categorySearchData.map((entry) => {
        return { name: entry.name, id: entry.id };
      });
    },
    warehouses() {
      return this.warehouse.map((entry) => {
        return { name: entry.text, id: entry.value };
      });
    },
    units() {
      return this.unitsSearchData.map((entry) => {
        return { name: entry.name, id: entry.id };
      });
    },
  },
  mounted() {
    this.getCategory();
    this.getUnit();
    this.getWarehouse();
    if (![null, undefined, '', 'null'].includes(this.$route.params.id)) {
      this.getItemInfo();
    }
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    'form.name': function (v) {
      this.form.print_name = v;
    },
    searchC(val) {
      if (!val) return;
      // if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.isLoading = true;
        this.$rest
          .get('api/account/inventory/category?search=' + val)
          .then(({ data }) => {
            this.categorySearchData = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },

    searchW(val) {
      if (!val) return;
      // if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        // this.isLoading = true;
        this.$rest
          .get('api/account/inventory/warehouse?search=' + val)
          .then(({ data }) => {
            this.wareHouseSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    searchU(val) {
      if (!val) return;
      // if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.isLoading = true;
        this.$rest
          .get('api/account/inventory/units?search=' + val)
          .then(({ data }) => {
            this.unitsSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    'addItemData.warehouse_id': function (v) {
      let wData = this.wareHouseSearchData.find((res) => res.id === v);
      this.addItemData.warehouse_name = wData ? wData.name : '';
    },
    'addItemData.unit_id': function (v) {
      let uData = this.unitsSearchData.find((res) => res.id === v);
      this.addItemData.unit_name = uData ? uData.name : '';
    },
    'addItemData.rate': function (v) {
      if (v && this.addItemData.qty) {
        this.addItemData.amount =
          parseFloat(v) * parseFloat(this.addItemData.qty);
      } else {
        this.addItemData.amount = 0;
      }
    },
    'addItemData.qty': function (v) {
      if (v && this.addItemData.rate) {
        this.addItemData.amount =
          parseFloat(v) * parseFloat(this.addItemData.rate);
      } else {
        this.addItemData.amount = 0;
        this.addItemData.rate = 0;
      }
    },
    'addItemData.amount': function (v) {
      if (v && this.addItemData.qty) {
        this.addItemData.rate =
          parseFloat(v) / parseFloat(this.addItemData.qty);
      }
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        '?' +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
              );
          })
          .join('&') +
        '&search=' +
        this.searchAH
      );
    },

    get(params) {
      // let query = [null, undefined].includes(params) ? this.queryString() : params;
      // this.form.get(null, query).then(({data}) => {
      //     this.pagination.totalItems = data.meta.total
      // })
    },
    getCategory() {
      this.$rest.get('/api/account/inventory/category').then(({ data }) => {
        data.data.map((item) => {
          if (item.is_primary) {
            this.category.push({
              value: item.id,
              text: item.name + ' ' + '(Primary)',
            });
          } else {
            this.category.push({
              value: item.id,
              text: item.name,
            });
          }
        });
      });
    },
    getUnit() {
      this.$rest.get('/api/account/inventory/units').then(({ data }) => {
        this.unit = [];
        data.data.map((item) => {
          this.unit.push({ value: item.id, text: item.name });
        });
      });
    },
    getWarehouse() {
      this.warehouse = [];

      this.$rest.get('/api/account/inventory/warehouse').then(({ data }) => {
        data.data.map((item) => {
          this.warehouse.push({ value: item.id, text: item.name });
        });
      });
    },
    editData(data) {
      this.udpate = true;
      this.categorySearchData = [];
      this.categorySearchData.push({
        name: data.parent_name,
        id: data.parent_id,
      });
    },

    save() {
      if (this.form.is_service_type) this.form.is_service_type = 1;
      else this.form.is_service_type = 0;

      if (this.form.is_taxable) this.form.is_taxable = 1;
      else this.form.is_taxable = 0;
      // this.form.parent_id = this.form.parent_id.id;
      this.form
        .store()
        .then((res) => {
          this.getItemInfo();
          // if (![null, undefined, '', 'null'].includes(this.$route.params.id)) {
          this.$router.push({ name: 'item' });
          // }
        })
        .catch((err) => {
          this.form.dialog = false;
          this.$events.fire('notification', {
            message: err.response.message,
            status: 'error',
          });
        });
    },
    searchItem: function (v) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get();
      }, 500);
    },
    addItem(item, index) {
      if (item.warehouse_id && item.qty && item.rate >= 0) {
        item.key = this.form.item_data.length;
        item.id = '';
        item.warehouse_name = this.findWareHouse(item.warehouse_id).name;
        // item.unit_name = this.findUnit(item.unit_id).name;

        this.form.item_data.push(item);
        this.update = false;
        this.addItemData = {
          key: '',
          id: '',
          warehouse_id: '',
          warehouse_name: '',
          qty: '',
          rate: '',
          // unit_id: '',
          // unit_name: '',
          amount: '',
        };
      }
    },
    removeItem: function (item) {
      var index = this.form.item_data.indexOf(item);
      this.form.item_data.splice(index, 1);
    },
    editItem(item, index) {
      this.update = true;
      this.editIndex = index - 1;
      this.addItemData = item;
      this.wareHouseSearchData.push({
        name: item.warehouse_name,
        id: item.warehouse_id,
      });
      this.unitsSearchData.push({ name: item.unit_name, id: item.unit_id });
      // this.dialog = true;
    },
    updateItem() {
      this.form.item_data[this.editIndex] = this.addItemData;
      this.dialog = false;
      this.addItemData = {};
      this.update = false;
      // this.form.item_data[this.editIndex].warehouse_name = this.addItemData.warehouse_name
      // this.form.item_data[this.editIndex].warehouse_name = this.addItemData.warehouse_name
    },
    findWareHouse(warehouse_id) {
      return this.warehouse.find((res) => res.value === warehouse_id);
    },
    findUnit(unit_id) {
      return this.units.find((res) => res.id === unit_id);
    },
    getItemInfo() {
      this.$rest
        .get('/api/account/inventory/item/' + this.$route.params.id)
        .then((res) => {
          this.form.id = res.data.id;
          this.form.category_id = res.data.category_id;
          this.form.unit_id = res.data.unit_id;
          this.form.name = res.data.name;
          this.form.item_code = res.data.item_code;
          this.form.print_name = res.data.print_name;
          this.form.sales_rate = res.data.sales_rate;
          this.form.purchase_rate = res.data.purchase_rate;
          this.form.min_sales_rate = res.data.min_sales_rate;
          this.form.mrp_sales = res.data.mrp_sales;
          this.form.is_service_type = res.data.is_service_type;
          this.form.is_taxable = res.data.is_taxable;
          this.form.notify.min_qty = res.data.notify
            ? res.data.notify.min_qty
            : '';
          this.form.notify.max_qty = res.data.notify
            ? res.data.notify.max_qty
            : '';
          this.form.notify.reorder_level = res.data.notify
            ? res.data.notify.reorder_level
            : '';
          this.form.item_data = res.data.openings;
          this.categorySearchData.push({
            id: res.data.category_id,
            name: res.data.category_name,
          });
          this.unitsSearchData.push({
            id: res.data.unit_id,
            name: res.data.unit_name,
          });
          // this.form = res.data;
        });
    },
    getItemCode() {
      this.$rest
        .get(
          `/api/account/inventory/item/item-code?category=${this.form.category_id}`
        )
        .then(({ data }) => {
          this.form.item_code = data;
        })
        .catch((err) => {
          this.$events.fire('notification', {
            message: err.message,
            status: 'error',
          });
        });
    },
  },
};
</script>
<style lang="scss">
.rowRap .flex.xs6 {
  font-weight: 500;
  font-size: 12px;
}

.itemRap .flex.xs6 {
  margin-top: -35px;
  margin-bottom: -50px;
  font-weight: 500;
  font-size: 12px;
}

.item_form_container {
  margin-top: 0px;
  /*background-color: red;*/
  display: flex;

  .item_form {
    flex: 3;
    padding: 15px;
    border-right: 1px dashed #aaa;
    /*background-color: green;*/
    form {
      width: 100%;
    }
  }

  .item_info {
    flex: 2;
    padding: 15px;
    /*background-color: orange;*/
  }

  .v-input__slot {
    margin-bottom: 4px;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: -3px;
  }
}
</style>
